<template>
  <div class="enterView" @click="pushRouter">
    <div class="enterTitle">
      {{ title }}
    </div>
    <div class="enterSubTitle">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'enterView',
  props: {
    title: {
      default: ''
    },
    subTitle: {
      default: ''
    },
    router: {
      default: ''
    }
  },
  methods: {
    pushRouter: function() {
      this.$emit('pushRouter', {
        router: this.router
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.enterView {
  width: 90%;
  transform: translate(5.5555%, 0px);
  border-radius: 10px;
  box-shadow: 3px 6px 10px #aaaaaa;
  padding-top: 15px;
  padding-bottom: 10px;

  .enterTitle {
    width: 90%;
    transform: translate(5.5555%, 0px);
    line-height: 30px;
    min-height: 30px;
    text-align: left;
    font-size: 18px;
    word-break: break-word;
    color: $color_primary;
  }

  .enterSubTitle {
    width: 90%;
    transform: translate(5.5555%, 0px);
    line-height: 20px;
    min-height: 20px;
    text-align: left;
    font-size: 12px;
    word-break: break-word;
    color: silver;
  }
}

</style>
