<template>
	<div class="mainEnter" :style="{ 'background-image': 'url(' + bgImg + ')' }">
		<div class="userInfo">
			<div v-if="showInfo" class="userImgBGView" @click="pushToView({'router':'mine'})">
				<img :src="photo" class="userImg" @error="userImgError">
			</div>
			<v-stack v-if="showInfo" class="userNameBGView" justify-content="space-between">
				<div class="title">
					名称: {{ userData.name }}
				</div>
				<div class="title">
					科室: {{ userData.tenantName }}
				</div>
				<div class="title">
					账号: {{ userData.username }}
				</div>
			</v-stack>
		</div>


		<div class="mainAction" :style="{height:'calc(100% - 123px)'}">
			<div justifyContent="space-between" v-for="(item) in appActionList" :key="item.key" class="mainSection"
				flex-wrap="wrap">
				<div v-if="showInfo" class="mainTitle">{{ item.title }}</div>
				<h-stack alignItems="flex-start" justifyContent="space-between" flex-wrap="wrap">

					<div v-for="(actionItem) in item.dataList" class="mainItem">
						<div class="mainContentView" @click="pushToView(actionItem)">
							<img :src="actionItem.img" class="iconImg">
							<div class="contentDiv">
								<div class="title textEllipsis">
									{{ actionItem.title }}
								</div>
								<div class="content">
									{{ actionItem.subTitle }}
								</div>
							</div>
						</div>
					</div>

				</h-stack>
			</div>
		</div>
	</div>

</template>

<script>
	import EnterView from '@/views/mainEnter/enterView/enterView'
	import VStack from '@/components/vStack/vStack'
	import HStack from '@/components/hStack/hStack'
	import roleEnterMixin from '@/views/mainEnter/roleEnterMixin'

	let userPhoto = require('../../../static/iconImage/yonghu.png')

	let pandian = require('../../../static/iconImage/pandian.png')

	let find = require('../../../static/iconImage/chazhao.png')

	let lanya = require('../../../static/iconImage/lanya.png')

	let chuangdai = require('../../../static/iconImage/chuangdai.png')

	let U1 = require('../../../static/iconImage/U1.png')

	let S2 = require('../../../static/iconImage/S2.png')

	let alarm = require('../../../static/iconImage/alarm.png')

	let yewu = require('../../../static/iconImage/yewu.png')

	let shebei = require('../../../static/iconImage/shebei.png')

	let jieyong = require('../../../static/iconImage/jieyong.png')

	let bgImg = require('../../../static/iconImage/mineBGImg.png')

	export default {
		name: 'mainEnter',
		components: {
			HStack,
			VStack,
			EnterView
		},
		mixins: [roleEnterMixin],
		data() {
			return {
				bgImg: bgImg,
				viewType: 'sso',
				showInfo: false,
				userData: {
					id: '',
					name: '',
					photo: '',
					tenantId: '',
					tenantName: '',
					username: ''
				},
			}
		},
		computed: {
			photo: function() {
				if (this.$valueIsExist(this.userData, 'photo') && this.userData['photo'] != '') {
					let photo = this.userData['photo']
					let url = `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${photo}&scale=0.1`
					return url
				}
				return userPhoto
			}
		},
		beforeRouteLeave(to, from, next) {
			if (this.loginType() == 'weChatQiYe' && to.name == 'login') {
				next(false)
				return
			}
			next(true)
		},
		created() {
			let showInfo = true
			if (typeof localStorage['showUserInfo'] != 'undefined') {
				let showUserInfo = localStorage['showUserInfo']
				showInfo = showUserInfo == '1' ? true : false
			}
			this.showInfo = showInfo

			let viewType = 'sso'
			if (typeof localStorage['viewType'] != 'undefined') {
				viewType = localStorage['viewType']
			}
			this.viewType = viewType

			this.$nextTick(() => {
				this.getUserPageInfo()
			})
		},
		mounted() {
			if (typeof localStorage['userInfo'] != 'undefined') {
				let weChatUserInfoStr = localStorage.getItem('userInfo')
				try {
					this.userData = JSON.parse(weChatUserInfoStr)
				} catch (e) {

				}
			}

		},
		methods: {
			userImgError(e) {
				e.target.src = userPhoto
			},
			reLogin: function() {
				if (this.$valueIsExist(localStorage, 'loginOut') == false) {
					return
				}
				this.removeLocalStorageEvent(this.reLogin)
				this.replaceToLogin()
				localStorage.removeItem('loginOut')
			},
			loginType: function() {
				if (typeof localStorage['loginType'] == 'undefined') {
					return ''
				}
				let loginType = localStorage.getItem('loginType')
				return loginType
			},
			pushToView: function(item) {
				let path = item.router

				if (path == '') {
					this.$message({
						type: 'error',
						message: '该功能尚未开放 (～￣▽￣)～'
					})
					return
				}

				if (path == 'mine') {
					this.addLocalStorageEvent(this.reLogin)
				}

				let query = {}
				if (this.$valueIsExist(item, 'query')) {
					query = item['query']
				}

				this.$push(path, query)
			},
			// 获取用户信息
			getUserInfo: function() {
				return new Promise(resolve => {
					this.$api.userInfo.getUserInfo({}).then(res => {
						let data = res.data
						for (const key in data) {
							if (typeof this.userData[key] == 'undefined') {
								continue
							}
							this.userData[key] = data[key]
						}

						if (this.loginType() == 'weChatQiYe' && typeof localStorage[
								'weChatUserInfo'] != 'undefined') {
							let weChatUserInfoStr = localStorage.getItem('weChatUserInfo')
							try {
								let weChatUserInfo = JSON.parse(weChatUserInfoStr)
								this.userData['photo'] = weChatUserInfo['avatar']
							} catch (e) {

							}
						}

						this.$store.commit('setUpUserInfo', this.userData)
						resolve({
							result: true
						})
					}).catch((e) => {
						resolve({
							result: false
						})
					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "@styles/variables.scss";

	.mainEnter {
		width: 100%;
		height: 100%;
		background-color: white;
		background-repeat: no-repeat;
		background-size: 100%;


		.userInfo {
			width: 100%;
			height: 123px;

			.userImgBGView {
				margin-left: 28px;
				float: left;
				width: 60px;
				height: 60px;
				position: relative;
				top: 50%;
				transform: translate(0px, -50%);

				.userImg {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					overflow: hidden;
				}

			}

			.userNameBGView {
				margin-left: 20px;
				float: right;
				width: calc(100% - 120px);
				height: 60px;

				position: relative;
				top: 50%;
				transform: translate(0px, -50%);

				.title {
					width: 100%;
					line-height: 20px;
					font-size: 13px;
					color: white;
					word-wrap: break-word;
					text-align: left;
				}


			}
		}


		.mainAction {
			background-color: white;
			padding: 0;
			width: 100%;
			overflow: scroll;
			border-radius: 30px;

			.mainSection {
				width: 100%;
				align-content: flex-start;


				.mainTitle {
					padding: 0px 20px 0px 23px;
					margin-top: 24px;
					width: 100%;
					line-height: 16px;
					padding-left: 24px;
					font-size: 16px;
					font-weight: bold;
				}


				.mainItem {
					width: 50%;

					&:nth-of-type(odd) {
						padding: 10px 20px 13px 23px;
					}

					&:nth-of-type(even) {
						padding: 10px 23px 13px 20px;
					}


					.mainContentView {
						width: 100%;
						//background: #FFFFFF;
						box-shadow: 0px 2px 12px 0px rgba(100, 101, 102, 0.12);
						border-radius: 24px;

						.iconImg {
							width: 48px;
							height: 48px;
							margin-top: 16px;
							position: relative;
							left: 50%;
							transform: translate(-50%, 0px);
						}

						.contentDiv {
							width: 100%;

							.title {
								padding: 24px 15px 0px 15px;
								width: 100%;
								line-height: 21px;
								font-size: 14px;
								font-weight: bold;
								color: #333333;
								text-align: center;
							}

							.content {
								padding: 0px 8px 22px 8px;
								line-height: 18px;
								font-size: 12px;
								color: #666666;
								text-align: center;
								word-wrap: break-word;
								word-break: break-all;
							}
						}
					}

				}


			}
		}
	}
</style>
