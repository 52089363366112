let userPhoto = require('../../../static/iconImage/yonghu.png')

let pandian = require('../../../static/iconImage/pandian.png')

let find = require('../../../static/iconImage/chazhao.png')

let lanya = require('../../../static/iconImage/lanya.png')

let chuangdai = require('../../../static/iconImage/chuangdai.png')

let U1 = require('../../../static/iconImage/U1.png')

let S2 = require('../../../static/iconImage/S2.png')

let alarm = require('../../../static/iconImage/alarm.png')

let yewu = require('../../../static/iconImage/yewu.png')

let shebei = require('../../../static/iconImage/shebei.png')

let jieyong = require('../../../static/iconImage/jieyong.png')

let roleMixin = {
  computed: {
    enterInfo() {
      return this.$enterPageInfo()
    },
    deviceWorker() {
      return {
        title: '维修处理',
        subTitle: '维修处理',
        router: 'deviceView1/deviceWorkerHome',
        img: shebei
      }
    },
    deviceMQView() {
      return {
        title: '设备MQ',
        subTitle: '设备MQ',
        router: 'deviceMQ',
        img: shebei
      }
    },
    deviceViewInfo() {
      return {
        title: '设备科',
        subTitle: '共享调度-设备科',
        router: 'deviceView1',
        img: shebei
      }
    },
    workManagerInfo() {
      return {
        title: '借出管理',
        subTitle: '共享调度-借出管理',
        router: 'workManager',
        img: yewu
      }
    },

    deviceBorrowInfo() {
      return {
        title: '借用管理',
        subTitle: '共享调度-借用管理',
        router: 'deviceBorrow',
        img: jieyong
      }
    },

    deviceManagerInfo() {
      return {
        title: '设备管理',
        subTitle: '设备管理',
        router: 'deviceManager',
        img: shebei
      }
    },
    narcotismManagerInfo() {
      return {
        title: '麻醉机应急调配',
        subTitle: '查看麻醉机应急调配',
        router: 'breatheManager',
        img: shebei,
        query:{
          title:'麻醉机',
          queryWord:'麻醉',
          breatheIds:'narcotismIds',
        },
      }
    },
    monitorManagerInfo() {
      return {
        title: '监护仪应急调配',
        subTitle: '查看监护仪应急调配',
        router: 'breatheManager',
        img: shebei,
        query:{
          title:'监护仪',
          queryWord:'监护',
          breatheIds:'monitorIds',
        },
      }
    },
    breatheManagerInfo() {
      return {
        title: '呼吸机应急调配',
        subTitle: '查看呼吸机应急调配',
        router: 'breatheManager',
        img: shebei,
      }
    },
    emergencySuppliesInfo() {
      return {
        title: '应急物资',
        subTitle: '查看应急物资分布',
        router: 'emergencySupplies',
        img: shebei
      }
    },
    alertInfo_Tpye_five() {
      return {
        title: '资产警报',
        subTitle: '查看资产报警',
        router: 'alertMessage',
        img: shebei,
        query: { alertType: 5 }
      }
    },
    alertInfo_Tpye_One() {
      return {
        title: '资产警报',
        subTitle: '查看资产报警',
        router: 'alertMessage',
        img: shebei,
        query: { alertType: 1 }
      }
    },
    panDianInfo() {
      return {
        title: '盘点',
        subTitle: '盘点资产数量',
        router: 'assetSimple',
        img: yewu
      }
    },
    historyCheckAssetInfo() {
      return {
        title: '历史盘点',
        subTitle: '历史盘点资产数量',
        router: 'checkAsset',
        img: yewu
      }
    },
    searchDeviceInfo() {
      return {
        title: '查找资产',
        subTitle: '查看资产位置',
        router: 'searchDevice',
        img: yewu
      }
    },
    mapRenderInfo() {
      return {
        title: '地图渲染',
        subTitle: '地图渲染工具',
        router: 'mapRenderList',
        img: shebei
      }
    },
    transportManageInfo() {
      return {
        title: '手术室运送',
        subTitle: '手术室运送管理系统',
        router: 'transportManage',
        img: shebei
      }
    },
    deviceRepair() {
      return {
        title: '资产报障',
        subTitle: '发现资产故障需要维修',
        router: 'deviceBorrow/deviceRepairMine',
        img: shebei,
      }
    },
    deviceRepairManager() {
      return {
        title: '资产维修',
        subTitle: '资产报障维修管理',
        router: 'deviceView1/deviceRepairHomeIndex/deviceRepairHome',
        img: shebei,
      }
    },
    searchZ8Info() {
      return {
        title: '一键寻物',
        subTitle: '一键寻物',
        router: '/pages/blueTooth/blueToothList/blueToothList',
        img: shebei,
        query: {
          weChat: true, path: '/pages/blueTooth/blueToothList/blueToothList',
          assetUrl: window.ipConfig.VUE_APP_ASSETURL,
          baseUrl: window.ipConfig.VUE_APP_BASEURL,
          originUrl: window.location.origin,
          token: ''
        }
      }
    },
    searchZ32Info() {
      return {
        title: '下发配置',
        subTitle: '下发配置',
        router: '/pages/blueTooth/blueToothList/blueToothList',
        img: shebei,
        query: {
          weChat: true, path: '/pages/blueTooth/blueToothList/blueToothList',
          assetUrl: window.ipConfig.VUE_APP_ASSETURL,
          baseUrl: window.ipConfig.VUE_APP_BASEURL,
          originUrl: window.location.origin,
          token: ''
        }
      }
    }
  },

  data() {
    return {
      appActionList: []

    }
  },
  methods: {
    getUserPageInfo: function() {
      let setPage = (info) => {
        let list = []
        let section = {
          key: 'info',
          title: '信息查看',
          dataList: []
        }

        list.push(section)

        if (this.$valueIsExist(info, 'inventory') && info['inventory'] > 0) {
          section.dataList.push(this.panDianInfo)
        }

        if (this.$valueIsExist(info, 'historyInventory') && info['historyInventory'] > 0) {
          section.dataList.push(this.historyCheckAssetInfo)
        }

        if (this.$valueIsExist(info, 'location') && info['location'] > 0) {
          section.dataList.push(this.searchDeviceInfo)
        }

        if (this.$valueIsExist(info, 'alert') && info['alert'] > 0) {
          section.dataList.push(this.alertInfo_Tpye_One)
        }

        if (this.$valueIsExist(info, 'emergencySupplies') && info['emergencySupplies'] > 0) {
          section.dataList.push(this.emergencySuppliesInfo)
        }

        if (this.$valueIsExist(info, 'deviceManager') && info['deviceManager'] > 0) {
          section.dataList.push(this.deviceManagerInfo)
        }

        if (this.$valueIsExist(info, 'alert_5') && info['alert_5'] > 0) {
          section.dataList.push(this.alertInfo_Tpye_five)
        }
        if (this.$valueIsExist(info, 'breatheManager') && info['breatheManager'] > 0) {
          section.dataList.push(this.breatheManagerInfo)
          section.dataList.push(this.monitorManagerInfo)
          section.dataList.push(this.narcotismManagerInfo)
        }

        if (this.$valueIsExist(info, 'workManager') && info['workManager'] > 0) {
          section.dataList.push(this.workManagerInfo)
        }

        if (this.$valueIsExist(info, 'deviceBorrow') && info['deviceBorrow'] > 0) {
          section.dataList.push(this.deviceBorrowInfo)
        }

        if (this.$valueIsExist(info, 'deviceView') && info['deviceView'] > 0) {
          section.dataList.push(this.deviceViewInfo)
        }

        if (this.$valueIsExist(info, 'deviceMQ') && info['deviceMQ'] > 0) {
          section.dataList.push(this.deviceMQView)
        }

        if (this.$valueIsExist(info, 'deviceWorkerHome') && info['deviceWorkerHome'] > 0) {
          section.dataList.push(this.deviceWorker)
        }

        if (this.$valueIsExist(info, 'mapRender') && info['mapRender'] > 0) {
          section.dataList.push(this.mapRenderInfo)
        }


        if (this.$valueIsExist(info, 'orderSend') && info['orderSend'] > 0) {
          section.dataList.push(this.transportManageInfo)
        }

        if (this.$valueIsExist(info, 'deviceRepair') && info['deviceRepair'] > 0) {
          section.dataList.push(this.deviceRepair)
        }

        if (this.$valueIsExist(info, 'deviceRepairManager') && info['deviceRepairManager'] > 0) {
          section.dataList.push(this.deviceRepairManager)
        }


        let access_token = ''
        let userInfostr = localStorage.getItem('userTokenInfo')
        try {
          let userInfo = JSON.parse(userInfostr)
          if (typeof userInfo['access_token'] != 'undefined') {
            access_token = userInfo['access_token']
          }
        } catch (e) {

        }
        if (this.$valueIsExist(info, 'z8Config') && info['z8Config'] > 0) {
          this.searchZ8Info.query.token = access_token;
          section.dataList.push(this.searchZ8Info)
        }

        if (this.$valueIsExist(info, 'z32Config') && info['z32Config'] > 0) {
          this.searchZ32Info.query.token = access_token;
          section.dataList.push(this.searchZ32Info)
        }

        this.appActionList = list

        let alertInfo = this.$store.getters.alertInfo
        if (typeof alertInfo['id'] == 'undefined' || alertInfo['id'] == null) {
          return
        }
        this.pushToView({ 'router': 'alertMessage' })

      }

      let page = this.enterInfo
      if (this.$valueIsExist(localStorage, 'userPageInfo')) {
        let jsonStr = localStorage['userPageInfo']
        try {
          page = JSON.parse(jsonStr)
        } catch (e) {

        }
      }
      page.searchZ32Info = true
      setPage(page)

    },

    defaultPage: function() {
      let ssoView = () => {
        this.appActionList = [
          {
            key: 'info',
            title: '信息查看',
            dataList: [this.panDianInfo, this.historyCheckAssetInfo, this.searchDeviceInfo, this.alertInfo_Tpye_One, this.emergencySuppliesInfo, this.breatheManagerInfo]
          }
        ]
      }

      let greenView = () => {
        this.appActionList = [
          {
            key: 'info',
            title: '',
            dataList: [this.alertInfo_Tpye_five]
          }
        ]
      }

      if (this.viewType == 'green') {
        greenView()
      } else {
        ssoView()
      }

      let info = this.$store.getters.alertInfo
      if (typeof info['id'] == 'undefined' || info['id'] == null) {
        return
      }

      let query = {}
      if (this.viewType == 'green') {
        query = { alertType: 5 }
      }
      this.pushToView({ 'router': 'alertMessage', query: query })
    }

  }
}

export default roleMixin
